import { httpToken } from "@/utils/request";
import { setTreedata } from "@/utils/util";
import qs from "qs";
// 获取数据字典
export function getdictionary(type_name, datatype = 1) {
  let json = [
    { column: "sort", type: 'orderByAsc' },
    { column: "type_name", type: 'eq', value: type_name }]
  return httpToken({
    method: "post",
    url: '/admin/maintain/data-dictionary/all',
    data: qs.stringify({
      paramData: JSON.stringify(json),
    }),
  }).then((res) => {
    if (datatype === 1) {
      res.data.forEach(item => {
        item.label = item.name,
        item.value = item.id
      })
    }else if (datatype === 2) {
      res.data.forEach(item => {
        item.label = item.name,
        item.value = item.name
      })
    }
    return res.data
  })
}
// 获取业务类型数据下拉
export function getworkTypeData(datatype=1,isAll=false) {
  return httpToken({
    method: "post",
    url: '/initiate-contract/workType/allStatus',
  }).then((res) => {
    if (datatype === 1) {
      res.data.forEach(item => {
        item.label = item.typeName,
        item.value = item.id
      })
    }
    if(isAll)
      res.data.unshift({label:'全部',value:null})
    return res.data
  })
}
// 获取部门下拉数据
export async function getdeptData(datatype = 1,url='/admin/sys-dept/tree',deptName='') {
  // let json = [
  //   { column: "sort", type: 'orderByAsc' },
  // ];
  return httpToken({
    method: "post",
    url: url,
    // data: qs.stringify({
    //   paramData: JSON.stringify(json)
    // }),
  }).then((res) => {
    setTreedata(res.data,datatype)
    return res.data
  })
}
// 获取菜单下拉数据
export function getmenuData(datatype = 1,url='/admin/sys-menu/tree') {
  return httpToken({
    method: "post",
    url: url,
  }).then((res) => {
    setTreedata(res.data,datatype)
    return res.data
  })
}
// 获取菜单类型下拉数据
export function getmenuTypeData(){
  return getXialaListdata2(['菜单','按钮'],['0','1'])
}
// 获取在职状态下拉数据
export function getJobStateData(){
  return getXialaListdata2(['在职','离职'],['0','1'])
}
// 获取合同状态下拉数据
export function getcontractData(type=1) {
  if(type==1)
    return getXialaListdata2(['待我处理','签署中','已完成','已过期','草稿箱','抄送'],['0','1','2','3','4','5'])
  else if(type==2)
    return getXialaListdata2(['签署中','已完成','已过期'],['1','2','3'])
  else if(type==3)
    return getXialaListdata2(['全部','待我处理','签署中','已完成','已过期','草稿箱','抄送'],[null,'0','1','2','3','4','5'])
}
// 获取认证方式下拉数据
export function getAuthenticationTypeData(){
  // return getXialaListdata2(['人脸识别认证','手机号认证','银行卡认证'],['1','2','3'])
  return getXialaListdata2(['手机号认证','银行卡认证'],['2','3'])
}
// 获取签署要求下拉数据
export function getRequirements(){
  return getXialaListdata2(['企业签章','经办人签名','法定代表人签章'],['0','1','2'])
}
// 获取认证状态下拉数据
export function getisCertificationData(){
  return getXialaListdata2(['未认证','认证','全部'],['0','1',null])
}
// 获取角色下拉数据
export function getroledata(datatype = 1,url='/admin/sys-role/all'){
  return httpToken({
    method: "post",
    url: url,
    data: qs.stringify({
      paramData: JSON.stringify([{ column: "sort", type: 'orderByAsc' }]),
    })
  }).then((res) => {
    if (datatype === 1) {
      res.data.forEach(item => {
        item.label = item.roleName,
        item.value = item.roleId
      })
    }
    return res.data
  })
}
// 获取岗位下拉数据 /sys-post/all
export function getpostdata(datatype = 1,url='/admin/sys-post/all'){
  return httpToken({
    method: "post",
    url: url,
    data: qs.stringify({
      paramData: JSON.stringify([{ column: "sort", type: 'orderByAsc' }]),
    })
  }).then((res) => {
    if (datatype === 1) {
      res.data.forEach(item => {
        item.label = item.postName,
        item.value = item.postId
      })
    }
    return res.data
  })
}
// 获取状态下拉数据 (用户)
export function getstatusData(){
  return getXialaListdata2(['正常','锁定'],['0','1'])
}
// 获取订单类型（0：线上充值，1：对公账户）
export function getrechargeTypeData(type=1) {
  let data=getXialaListdata2(['线上充值','对公账户'],['0','1'])
  if(type==2)
    data.unshift({label:'全部',value:null})
  return data
}
// 支付渠道（0：支付宝，1：微信，2：线下汇款）
export function getpaymentMethodData(type=1) {
  let data=getXialaListdata2(['支付宝','微信','线下汇款'],['0','1','2'])
  if(type==2)
    data.unshift({label:'全部',value:null})
  return data
}
// 支付状态(0-订单生成, 1-支付中, 2-支付成功, 3-支付失败, 4-已撤销, 5-已退款, 6-订单关闭)
export function getpaymentStatusData(type=1) {
  let data=getXialaListdata2(['订单生成','支付中','支付成功','支付失败','已撤销','已退款','订单关闭'],['0','1','2','3','4','5','6'])
  if(type==2)
    data.unshift({label:'全部',value:null})
  return data
}
// 是否开票(0未开票，1已开票，2待审核)
export function getinvoicingData(type=1) {
  let data=getXialaListdata2(['未开票','已开票','待审核'],['0','1','2'])
  if(type==2)
    data.unshift({label:'全部',value:null})
  return data
}
// 消费类型（0：合同）
export function getconsumeTypeData(type=1) {
  let data=getXialaListdata2(['合同','套餐'],['0','1'])
  if(type==2)
    data.unshift({label:'全部',value:null})
  return data
}

// arr 标题 arr2 值
export function getXialaListdata2 (arr, arr2) {
  let a = [];
  for (let i = 0; i < arr.length; i++) {
      a.push({
          value: arr2[i],
          label: arr[i]
      })
  }
  return a;
}
// 获取水印位置数据
export function getwatermarkData () {
 return [
    {
      image: require("@/assets/img/initiateContract/class1.png"),
      imageA: require("@/assets/img/initiateContract/aclass1.png"),
      title:'左上',
    },
    {
      image: require("@/assets/img/initiateContract/class2.png"),
      imageA: require("@/assets/img/initiateContract/aclass2.png"),
      title:'右上',
    },
    {
      image: require("@/assets/img/initiateContract/class3.png"),
      imageA: require("@/assets/img/initiateContract/aclass3.png"),
      title:'左下',
    },
    {
      image: require("@/assets/img/initiateContract/class4.png"),
      imageA: require("@/assets/img/initiateContract/aclass4.png"),
      title:'右下',
    },
    {
      image: require("@/assets/img/initiateContract/class5.png"),
      imageA: require("@/assets/img/initiateContract/aclass5.png"),
      title:'居中',
    },
    {
      image: require("@/assets/img/initiateContract/class6.png"),
      imageA: require("@/assets/img/initiateContract/aclass6.png"),
      title:'平铺',
    },
    // {
    //   image: require("@/assets/img/initiateContract/class7.png"),
    //   imageA: require("@/assets/img/initiateContract/aclass7.png"),
    //   title:'填充',
    // },
  ]
}
//获取印章类型
export function sealType (e=2,is=false){
    if(e==1){
      return[
        {label:'全部',value:null},
        {label:'公章',value:'3'},
        {label:'法定代表人章',value:'4'},
        {label:'业务章',value:'5'}
      ]
    }else if(e==2){
      let data=[
        {label:'个人签名',value:'1'},
        {label:'个人印章',value:'2'},
        {label:'公章',value:'3'},
        {label:'法定代表人章',value:'4'},
        {label:'业务章',value:'5'}
      ]
      if(is){
        data.unshift({label:'全部',value:null})
      }
      return data
    }else if(e==3){
      let data=[
        {label:'公章',value:'3'},
        {label:'法定代表人章',value:'4'},
        {label:'业务章',value:'5'}
      ]
      if(is){
        data.unshift({label:'全部',value:null})
      }
      return data
    }
}
export function searchSealType(){
  return getXialaListdata2(['全部', '公章', '法定代表人章', '业务章'], [null, 3, 4, 5])
}
//部门列表
// /sys-dept/all
export function getdept(datatype = 1,url='/admin/sys-dept/all'){
  return httpToken({
    method: "post",
    url: url,
    data: qs.stringify({
      paramData: JSON.stringify([{ column: "sort", type: 'orderByAsc' }]),
    })
  }).then((res) => {
    if (datatype === 1) {
      res.data.forEach(item => {
        item.label = item.name,
        item.value = item.deptId
      })
    }
    return res.data
  })
}
//模版类型
export function gettemplate(datatype = 1,url='/admin/templateCon/all'){
  return httpToken({
    method: "post",
    url: url,
    data: qs.stringify({
      paramData: JSON.stringify([{ column: "sort", type: 'orderByAsc' }]),
    })
  }).then((res) => {
    if (datatype === 1) {
      res.data.forEach(item => {
        item.label = item.templateTitle,
        item.value = item.id
      })
    }
    return res.data
  })
}