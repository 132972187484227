<template>
  <div class="w100 bg-fff wtm_height pl-24 pr-24 pt-30 box autobox">
    <seanhead @searh="searh"></seanhead>
    <div class="signbox w100 dp-f fw-w" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
      <div class="morebox mr-14 dp-fc cu-p" @click="newSeal">
        <img class="mr-4 pt-2" src="@/assets/img/general/add.png" />新增印章
      </div>
      <signItems :datas="state.Notice" @DetailEmit="todetail" :type="3"></signItems>
    </div>
    <dialog22 ref="newsign" :options="state.options" @updatelist="updatelist"></dialog22>
    <el-pagination class="dp-f jc-c mb-20" @current-change="handleCurrentChange" background
      layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage" :page-size="9">
    </el-pagination>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, unref } from "vue";
import seanhead from "./seanhead.vue";
import signItems from "./signItems.vue";
import dialog22 from "./my/dialog.vue"; //新增弹出框
import { router_push_name } from "@/utils/server/router.js";
import {sealType} from '@/utils/server/selectdata'
import { fa } from "element-plus/es/locale";
import qs from "qs";
import { httpToken } from "@/utils/request";
import { configu } from "@/utils/config"; //引入公用文件
const loading = ref(false); //loading 显示
const currentPage = ref(1); //当前页数
const totalPage = ref(0); //总条数
const dialogVisible = ref(true); //弹出框显示
const newsign = ref(); //新增弹出框
const dialogVisibletitle = ref("制作电子印章"); //弹出框标题
const ruleFormRef = ref();
const state = reactive({
  // status 1 通过 2停用 3 待审核 4未通过
  Notice: [],
  sealType: "",
  loading: false,
  datasearchjson: [],
  options:sealType(3)
});
// 分页改变方法
const handleCurrentChange = (e) => {
  currentPage.value = e;
  getdatalb(e,state.datasearchjson);
};
//新增印章
const newSeal = () => {
  unref(newsign).addseal();
  unref(newsign).getTemplate();
};
//查看详情
const todetail = (e) => {
  router_push_name("sign_sealParticulars", e);
};
// 列表调取 页数 传入构造器
let getdatalb = (val, searchjson = []) => {
  loading.value = true;
  let json = [
    // { column: "sort", type: 'orderByAsc' },
    // { column: "type_name", type: 'eq',value:state.sidebaritem.typeName }
  ];
  for (let i = 0; i < searchjson.length; i++) {
    json.push(searchjson[i]);
  }
  currentPage.value = val;
  state.datasearchjson = searchjson;
  httpToken({
    method: "post",
    url: "/seal/seal-admin/list",
    data: qs.stringify({
      page: val,
      rows: 9,
      paramData: JSON.stringify(json),
    }),
  })
    .then((res) => {
      // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
      if (val !== res.data.current) {
        getdatalb(res.data.current, searchjson);
        return;
      }
      totalPage.value = res.data.total;
      // totalofpages.value = res.data.pages;
      state.Notice = res.data.records;
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
    });
};
const searh=(e)=>{
  state.datasearchjson=[
  { column: "seal_status", type: 'eq',value:e.status },
  { column: "seal_type", type: 'eq',value:e.type },
  { column: "seal_name", type: 'like',value:e.content}
  ]
  getdatalb(currentPage.value, state.datasearchjson);

}
const updatelist=()=>{
  getdatalb(currentPage.value, state.datasearchjson);
}
onMounted(() => {
  getdatalb(currentPage.value, state.datasearchjson);
});
</script>
<style lang="scss" scoped >
@import "@/styles/color/value.scss";
.box {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.morebox {
  width: 250px;
  height: 310px;
  background: linear-gradient(180deg,
      rgba(238, 199, 116, 0) 0%,
      $active-alpha 100%);
  border-radius: 10px;
  border: 1px solid $active-alpha;
  font-weight: 500;
  color: #333333;
}

.signbox {
  min-height: 600px;
}
</style>