<template>
  <!-- // status 1 通过 2停用 3 待审核 4未通过 5 使用中 -->
  <div class="sign dp-f fd-c mr-14 mb-14" :style="{ 'height': props.height + 'px' }" v-for="(el, ind) in props.datas"
    :key="ind">
    <div class="imgbox pt-r">
      <div class="h-220 dp-fc">
        <img :src="el.sealUrl" class="cu-p w-112 " @click="emit('DetailEmit', { id: el.sealId })" />
      </div>
      <div class="pt-a w100 h100 dp-fc outservice" v-if="el.sealStatus == 2">
        已停用
      </div>
      <div class="icon pt-a" :class="'icon' + el.status" v-if="el.sealStatus == 3 || el.sealStatus == 4">
        {{ getsignStatus(el.sealStatus) }}
      </div>
    </div>
    <div class="w100 h100 dp-f fd-c jc-c">
      <div class="w100 ta-c t-oh cu-p title" :class="props.type == 1 ? 'fs-18' : ''" @click="emit('DetailEmit', { id: el.sealId })">
        {{ el.sealName }}</div>
      <template v-if="props.type == 1">
        <div class="dp-f jc-c mt-12" v-if="el.sealStatus == 3 || el.sealStatus == 4" v-permission="'sign:audit'">
          <div class="cu-p mr-36" style="color: #1890FF;" @click="emit('reviewEmit', { id: el.sealId })">拒绝</div>
          <div class="cu-p" style="color: #1890FF;" @click="emit('reviewEmit', { id: el.sealId })">通过</div>
        </div>
      </template>
      <template v-if="props.type == 2">
        <!-- <div v-if="el.sealStatus==1" class="t-oh ta-c call mt-2 cu-p">
            申请调用
          </div> -->
        <div v-if="el.sealStatus == 1" class="t-oh ta-c maturity mt-2">
          <!-- 2022-12-24 14:23:12 到期 -->
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineEmits, defineProps, onMounted, watch } from 'vue'
import { getsignStatus } from "@/utils/server/getcode.js"
const emit = defineEmits(['DetailEmit', 'reviewEmit'])
const props = defineProps({
  type: {
    type: Number,
    default: 1
  },//类型 1控制台 2操作台我的印章
  height: {
    type: Number,
    default: 310
  },//高度
  datas: {
    type: Array,
    default: [],
  },//传入的印章数据
})
onMounted(() => {
})
const state = reactive({

})
</script>
<style lang="scss">
.sign {
  flex-shrink: 0;
  width: 250px;
  box-shadow: 0px 0px 7px 0px #FBF2DF;
  border-radius: 10px;
  border: 1px solid #DEDEDE;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  .title {
    color: #333333;
  }

  .imgbox {
    width: 100%;
    height: 250px;
    padding: 14px;
    border-bottom: 1px solid #DEDEDE;
    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    .icon {
      top: 14px;
      left: 14px;
      padding: 5px 13px;
      color: #292728;
      background: rgba(238, 199, 116, 0.4);
      border-radius: 16px;
    }

    .icon4 {
      background: rgba(196, 196, 196, 0.4);
      color: #666666;
    }

    .outservice {
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.9);
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FF4D4F;
    }
  }

  .maturity {
    color: #FF4D4F;
  }

  .call {
    color: #1890FF;
  }
}</style>